@import "/src/styles/mixin";

.nested {
  &Wrap {
    .extended {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 12px;
    }

    .compressed {
      gap: 12px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }

    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }

  &Title {
    @include font20;
    margin-bottom: 32px;
  }

  &Text {
    @include font14;
    margin-bottom: 24px;
  }

  &Minicards {
    margin-bottom: 68px;
    display: flex;
    gap: 12px;
    flex-wrap: nowrap;

    @media screen and (max-width: 768px) {
      margin-bottom: 85px;
    }

    @media screen and (max-width: 425px) {
      margin-bottom: 33px;
    }

    &Active {
      flex-direction: column;
    }

    &NotActive {
      flex-direction: row;
    }
  }

  &Cards {
    display: flex;
    flex-direction: column;
  }

  &Payments {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
}

.choice {
  &Wrap {
    margin-bottom: 48px;

    &:first-child {
      div:first-child {
        padding: 12px 16px;

        * {
          padding: 0;
        }
      }
    }
  }

  &Text {
    @include font14;
    margin-bottom: 8px;
    color: var(--grey-color);
  }
}

.order {
  &Choose {
    &Delivery {
      &Wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 36px;
        margin-bottom: 52px;

        @media screen and (max-width: 767px) {
          flex-wrap: nowrap;
          background-color: var(--blue-background-color);
          padding: 8px 8px;
          gap: 0px;
          border-radius: var(--border-radius-small);
        }
      }

      &Button {
        @include font14;
        position: relative;
        padding: 24px 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        border: 1px solid transparent;
        background-color: var(--blue-background-color);
        border-radius: var(--border-radius-small);
        transition:
          border 0.2s ease,
          background 0.2s ease;
        width: 47%;

        &IsActive {
          border-color: var(--primary-color);
          background-color: rgba(255, 221, 45, 0.05);

          @media screen and (max-width: 767px) {
            background-color: var(--white-color);
            border: none;
          }
        }

        @media screen and (min-width: 1024px) and (max-width: 1321px) {
          width: 100%;
        }

        @media screen and (max-width: 1024px) {
          padding: 24px 30px;
        }

        @media screen and (max-width: 767px) {
          min-width: 46%;
          padding: 12px;
          background-color: transparent;
        }

        @media screen and (max-width: 425px) {
          padding: 6px;
        }
      }
    }
  }
}
