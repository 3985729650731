@import "/src/styles/mixin";

.tab_button {
  @include font24Bold;
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 3px solid transparent;
  white-space: nowrap;
  &:not(.tab_active) {
    @include lineHover;
    &::before {
      height: 3px;
      background-color: var(--primary-color);
    }
  }
}

.tab_active {
  position: relative;
  transition: color 0.2s ease;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: var(--primary-color);
  }
}

.sub_text {
  @include font16Bold;
  padding-left: 10px;
  position: relative;
  align-self: flex-end;
  line-height: 36px;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 5px;
    width: 1px;
    height: 70%;
    background-color: var(--grey-lines-color);
    transform: translateY(-50%);
  }
  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    line-height: 32px;
  }
}
