@use "src/styles/mixins/ff" as *;
@import "src/styles/mixin";

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 20px;
  box-shadow: 0px 0px 8px 0px #00000026;
  border-radius: var(--border-radius-small);
  gap: 10px 24px;
  background-color: var(--white-color);

  .info {
    display: flex;
    flex-direction: column;
    text-align: left;

    .title {
      @include ff(18px, 700, 24px);

      @media screen and (max-width: 544px) {
        @include ff(17px, 500, 20px);
      }
    }

    .subtitle {
      @include ff(14px, 500, 28px);

      @media screen and (max-width: 544px) {
        @include ff(13px, 400, 22px);
      }
    }

    @media screen and (max-width: 544px) {
      text-align: center;
      gap: 4px;
    }
  }

  .button {
    @include contentButton;
    @include ff(14px, 700, 24px);
    padding: 14px 20px;
    white-space: nowrap;
  }

  @media screen and (max-width: 544px) {
    flex-direction: column;
    justify-content: center;
    padding: 10px;
  }
}
