@import "/src/styles/mixin";

.title {
  @include font30;
  @include titleH1;
  margin-bottom: 40px;
}
.subtitle {
  @include font24;
  margin-bottom: 24px;
}
.link {
  text-decoration: underline;
}
.item {
  max-width: 948px;
  &:not(:last-child) {
    margin-bottom: 48px;
  }
  &Title {
    @include font18;
    margin-bottom: 6px;
  }
  &Text {
    @include font16;
    &:not(:last-child) {
      margin-bottom: 40px;
    }
    &Info {
      @include font16Bold;
      max-width: 1071px;
      margin-bottom: 50px;
      padding: 36px 46px;
      background-color: var(--blue-background-color);
    }
  }
}
.list {
  list-style-type: "—";
  padding-left: 12px;
  &Item {
    @include font16;
    padding-left: 8px;
  }
}
