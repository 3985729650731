@import "/src/styles/mixin";

.card__point {
  background-color: var(--blue-background-color);
  border-radius: var(--border-radius-small);
  display: flex;
  gap: 8px;
  padding: 7px 12px;
  text-align-last: left;
  align-items: center;

  @media screen and (max-width: 425px) {
    padding: 4px 8px;
  }

  .card__point_info {
    display: flex;
    column-gap: 8px;
    flex-wrap: wrap;
    flex-direction: column;

    .card__point_choosed {
      @include font14;
      font-weight: 700;
    }

    .quantity_wrap {
      display: flex;
      align-items: center;
      column-gap: 4px;
      row-gap: 2px;

      .card__point_schedule {
        @include font12;
        color: var(--grey);
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 24px;

        @media screen and (min-width: 1025px) and (max-width: 1300px) {
          white-space: nowrap;
          padding: 4px 8px;
        }

        @media screen and (max-width: 425px) {
          font-weight: 500;
        }
      }

      .quantity {
        @include font14;
        font-weight: 600;
        color: var(--grey);

        @media screen and (max-width: 425px) {
          @include font12;
          font-weight: 500;
        }
      }
    }
  }

  .type_text {
    @include font14;
    margin-top: auto;
    margin-bottom: auto;
    white-space: nowrap;

    @media screen and (max-width: 425px) {
      display: none;
    }
  }
}
