@import "/src/styles/mixin";

.wrap {
  width: 100%;
  height: 100%;
}

.title {
  @include font24;
  margin-bottom: 24px;
  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 16px;
  }
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    margin-bottom: 12px;
  }
}

.hamburger {
  display: none;
  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    display: block;
  }
}
