@import "/src/styles/mixin";

.header {
  margin-bottom: 72px;
  width: fit-content;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(2, auto);
  gap: 24px;
}

.step {
  @include font20;
  grid-area: 2 / 1 / 3 / 2;
  display: flex;
  align-items: center;
  padding: 0 10px;
  background-color: var(--primary-color);
  border-radius: var(--border-radius-small);
}

.title {
  @include font24;
  grid-area: 2 / 2 / 3 / 3;
}

.back {
  display: flex;
  align-items: center;
  gap: 8px;
}

.icon {
  transform: rotate(180deg);
}
