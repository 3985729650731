@use "src/styles/mixins/ff" as *;
@import "/src/styles/mixin";

.button {
  @include contentButton;

  & > * {
    position: relative;
    z-index: 1;
  }

  &.loading {
    @include ff(16px, 600, 24px);
    letter-spacing: 0.05em;
    position: relative;
    overflow: hidden;
    color: var(--secondary-color);
    background-color: var(--white-color);
    background-image: repeating-linear-gradient(
      -45deg,
      var(--yellow-10),
      var(--yellow-10) 10px,
      var(--yellow-20) 10px,
      var(--yellow-20) 20px
    );
    background-size: 200% 200%;
    border: 1px solid transparent;
    animation: loading-animation 10s linear infinite;
    z-index: 0;
  }
}

@keyframes loading-animation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
}
