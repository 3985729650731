@use "src/styles/mixins/ff" as *;

.info {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 12px;
  width: 100%;
  min-width: 280px;

  .text {
    @include ff(16px, 400, 24px);
  }
}
