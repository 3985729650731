.banners {
  padding-top: 0;
  padding-bottom: 0;

  .banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: var(--blue-background-color);
    aspect-ratio: 1907 / 530;

    &_without__link {
      cursor: pointer;
    }

    @media screen and (max-width: 767px) {
      aspect-ratio: 767 / 450;
    }
  }
}
