@import "/src/styles/mixin";

.card {
  position: relative;
  padding: 16px 0;
  display: flex;
  flex-wrap: nowrap;
  column-gap: 16px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--grey-lines-color);
  }

  .card_photo__wrap {
    aspect-ratio: 1;
    border-radius: var(--border-radius-small);
    background-color: var(--blue-background-color);
    max-width: 150px;
    max-height: 150px;
    width: 100%;
    height: auto;
    object-fit: contain;

    @media screen and (max-width: 535px) {
      max-width: 85px;
      max-height: 85px;
      height: 85px;
    }

    .card_photo {
      height: 100%;
      width: 100%;
      align-self: flex-start;
      object-fit: contain;
      mix-blend-mode: multiply;
      padding: 8px;
      margin-right: 16px;

      @media screen and (max-width: 535px) {
        width: 85px;
        height: 85px;
        max-width: 85px;
        max-height: 85px;
      }
    }
  }

  &_name {
    @include font14;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    &_tag {
      justify-content: space-between;
      display: flex;
      column-gap: 8px;

      @media screen and (max-width: 425px) {
        flex-direction: column;
      }
    }
  }

  &_tag {
    @include font14;
    @include dashedBottomLine;
    color: var(--grey-color);
    display: flex;
    white-space: nowrap;

    &Wrap {
      display: flex;
      justify-self: flex-end;
      flex-direction: column;
      align-items: flex-end;
      gap: 6px;
    }
  }

  &_delivery {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

    &__wrap {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &_price {
      @include font14;
      padding: 0 8px;
      background-color: var(--primary-color);
    }

    &__text {
      @include font12;
      font-weight: 500;
    }
  }

  &Point {
    &Wrap {
      grid-area: 2 / 2 / 3 / 4;
    }
  }

  &Result {
    &Text {
      @include font14;
      margin-bottom: 12px;
    }
  }

  .card_info {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
