@import "/src/styles/mixin";

.wrap {
  width: 100%;
  max-width: 874px;
  display: flex;
  gap: 24px;

  &_info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  @media screen and (max-width: 1365px) {
    flex-direction: column;
    align-items: self-start;
  }
}

.social {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;
}

.user {
  &Wrap {
    width: 100%;
    padding: 24px;
    border: 1px solid var(--grey-lines-color);
    border-radius: var(--border-radius-normal);
    display: flex;
    height: fit-content;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
}

.text {
  @include font14;
  color: var(--black-color);

  &Title {
    @include font14;
    color: var(--grey-color);
  }
}

.button {
  @include font14;
  padding: 12px 16px;
}

.qr {
  &Link {
    @include contentButton;
    @include font14;
    padding: 12px 16px;
    text-align: center;
  }
}
