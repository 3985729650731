@import "/src/styles/mixin";

.wrap {
  display: flex;
  gap: 8px;
  align-items: center;
}

.title {
  @include font12;
  font-weight: 500;
}

.tooltip {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: var(--grey-lines-color);
  color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  &Text {
    @include font12;
    font-weight: 500;
    padding: 12px;
    position: absolute;
    top: 150%;
    right: 50%;
    margin-right: -140px;
    width: 400px;
    background-color: var(--grey-lines-color);
    border-radius: var(--border-radius-small);
    color: var(--black-color);
    visibility: hidden;
    opacity: 0;
    transition:
      opacity 0.2s ease,
      visibility 0.2s ease,
      transform 0.2s ease;
    text-align: left;
    z-index: 1;
    &::after {
      content: "";
      position: absolute;
      bottom: 100%;
      right: 34%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent var(--grey-lines-color) transparent;
    }
    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      margin-right: -125px;
      width: 270px;
      &::after {
        right: 44%;
      }
    }
  }
  &:focus,
  &:hover {
    .tooltipText {
      visibility: visible;
      opacity: 1;
      transform: translateY(5px);
    }
  }
}
