@import "/src/styles/mixin";

.card {
  position: relative;
  padding: 16px 0;
  display: grid;
  grid-template-columns: 166px 1fr 160px;
  grid-template-rows: repeat(2, auto);
  gap: 8px;
  overflow: hidden;

  @media screen and (max-width: 570px) {
    grid-template-columns: 87px 1fr 160px;
  }

  @media screen and (max-width: 425px) {
    grid-template-columns: 87px 1fr 110px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--grey-lines-color);
  }

  &Photo {
    mix-blend-mode: multiply;
    aspect-ratio: 1;
    object-fit: contain;
    width: 150px;
    height: 150px;

    @media screen and (max-width: 570px) {
      width: 85px;
      height: 85px;
      spect-ratio: 1 / 1;
    }

    &Wrap {
      max-width: 150px;
      max-height: 150px;
      align-self: flex-start;
      grid-area: 1 / 1 / 3 / 2;
      object-fit: contain;
      padding: 8px;
      border-radius: var(--border-radius-small);
      background-color: var(--blue-background-color);
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 570px) {
        width: 85px;
        height: 85px;
        spect-ratio: 1 / 1;
      }
    }
  }

  &Name {
    @include font14;

    &Wrap {
      grid-area: 1 / 2 / 3 / 4;
      margin-bottom: 12px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      text-overflow: ellipsis;
      height: fit-content;

      @media screen and (max-width: 425px) {
        max-width: 100%;
        margin-bottom: 0;
        margin-bottom: auto;
        grid-area: 1 / 2 / 3 / 4;
      }

      @media screen and (max-width: 375px) {
        grid-area: 1 / 2 / 4 / 4;
      }
    }
  }
  &Price {
    display: flex;
    flex-direction: column;
    grid-area: 1 / 4 / 2 / 4;
    .current_price {
      @include font18;
      color: var(--secondary-color);
    }
    .old_price {
      @include font14;
      font-weight: 500;
      text-decoration: line-through;
      color: var(--secondary-color);
    }
  }
  // &Tag {
  //   @include font14;
  //   @include dashedBottomLine;
  //   color: var(--grey-color);
  //   white-space: nowrap;

  //   &Wrap {
  //     grid-area: 1 / 2 / 2 / 4;
  //     display: flex;
  //     justify-self: flex-end;
  //     flex-direction: column;
  //     align-items: flex-end;
  //     gap: 6px;
  //     height: fit-content;

  //     @media screen and (max-width: 425px) {
  //       margin-right: auto;
  //       grid-area: 1 / 2 / 2 / 2;
  //     }
  //   }
  // }

  &Delivery {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &Wrap {
      display: flex;
      flex-direction: column;
      gap: 8px;
      grid-area: 2 / 2 / 4 / 3;

      @media screen and (max-width: 570px) {
        grid-area: 3 / 2 / 4 / 3;
      }
    }

    &Price {
      @include font14;
      padding: 0 8px;
      background-color: var(--primary-color);
    }

    &Text {
      @include font12;
      font-weight: 500;
    }
  }

  &Point {
    &Wrap {
      grid-area: 2 / 2 / 3 / 4;

      @media screen and (max-width: 425px) {
        grid-area: 2 / 2 / 3 / 2;
      }
    }
  }

  &Result {
    &Text {
      @include font14;
    }

    &Wrap {
      margin-bottom: 2px;
      width: 100%;
      height: fit-content;
      margin-top: auto;
      grid-area: 2 / 2 / 3 / 5;
      @media screen and (min-width: 1023px) and (max-width: 1200px) {
        grid-area: 3 / 1 / 3 / 5;
        max-width: 100%;
        overflow: hidden;
      }

      @media screen and (max-width: 570px) {
        grid-area: 3 / 1 / 3 / 5;
        margin-top: 12px;
      }

      @media screen and (max-width: 425px) {
        grid-area: 3 / 1 / 3 / 5;
        max-width: 100%;
        overflow: hidden;
      }
    }
  }
}
