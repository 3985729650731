@import "../../../../../styles/mixin";

.form {
  width: 100%;
  max-width: 468px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 24px;
  background-color: var(--white-color);
  border: 1px solid var(--grey-lines-color);
  border-radius: var(--border-radius-normal);

  .message {
    @include font12;
    font-weight: 400;
    color: var(--grey-third);
  }

  &__without_date {
    gap: 0;

    @media screen and (min-width: 1024px) and (max-width: 1200px) {
      gap: 16px;
    }

    @media screen and (min-width: 320px) and (max-width: 830px) {
      gap: 16px;
    }
  }

  .birthdayAndPhone {
    display: flex;
    gap: 16px;
    width: 100%;

    @media screen and (min-width: 320px) and (max-width: 830px) {
      flex-direction: column;
    }
  }

  .date {
    width: 100%;
    min-width: 141px;
    transition: all 0.25s;
  }

  .phone {
    width: 100%;
    min-width: 234px;
  }

  .phone_hidden {
    display: none;
  }

  .button {
    padding: 12px 16px;
    background-color: var(--primary-color);
    border-radius: var(--border-radius-small);
    width: fit-content;

    @media screen and (min-width: 320px) and (max-width: 830px) {
      width: 100%;
    }
  }

  .button_disabled {
    @include font14;
    padding: 12px 16px;
    background-color: var(--blue-background-color);
    border-radius: var(--border-radius-small);
    width: fit-content;
    cursor: not-allowed;

    @media screen and (min-width: 320px) and (max-width: 830px) {
      width: 100%;
    }
  }

  .readonly_info {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .info_elem {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .title {
        @include font14;
        color: var(--grey-darker-color);
      }

      .text {
        @include font14;
      }
    }
  }
}
