@import "src/styles/mixin";

.empty {
  width: 100%;
  height: 58vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .title {
    @include font20;
    margin-bottom: 12px;
  }

  .text {
    @include font14;
    margin-bottom: 24px;
  }

  .link {
    @include font14;
    @include contentButton;
    padding: 6px 26px;
  }
}
